import React from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../../constant";

const GradientCard = styled(Card)({
  width: 200,
  height: 200,
  background:
    "linear-gradient(90deg, rgba(2,0,36,1) 0%, #3d3dee 0%, #05b3fdeb 100%)",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: "#fff", // White text for contrast
});

const UpgradeCard = () => {
  const navigate = useNavigate();
  return (
    <GradientCard>
      <CardContent sx={{ textAlign: "center" }}>
        <Typography variant="h6" gutterBottom>
          Free Trial
        </Typography>
        <Typography variant="body2" gutterBottom>
          You are currently on a free trial.
        </Typography>
        <Box mt={2}>
          <Button
            variant="contained"
            onClick={() => navigate(PATH.pricing)}
            sx={{ textTransform: "none" }}
            color="primary"
          >
            Upgrade Plan
          </Button>
        </Box>
      </CardContent>
    </GradientCard>
  );
};

export default UpgradeCard;
