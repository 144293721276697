export const PATH = {
  home: "/",
  termsofservice: "/termsofservice",
  question: "/question",
  pricing: "/pricing",
  subscription: "/subscription",
  admin: "/admin",
  editFirms: "/editfirms",
  category: "/category",
  editCategory: "/editcategory",
  setQuestion: "/setquestion",
  editSetQuestion: "/editsetquestion",
  iHomies: "/v2",
  testimonials: "/testimonials",
};
