import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";

import { Icon } from "@iconify/react";
import { PATH } from "../../constant";
import { useNavigate } from "react-router-dom";

import ConfirmDialog from "../../custom-dialog/confirm-dialog";
import { useBoolean } from "../../hooks/use-boolean";
import toast from "react-hot-toast";
import FirmDialog from "../firmDialog";

interface Column {
  id: "name" | "isActive" | "action";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "isActive", label: "isActive", minWidth: 100 },
  {
    id: "action",
    label: "Action",
  },
];

interface Data {
  name: string;
  isActive: boolean;
}

function createData(name: string, isActive: boolean): Data {
  return { name, isActive };
}

function FirmsList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [firm, setFirm] = React.useState([]);
  const rows: any[] = firm;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const confirm = useBoolean();

  const [deleteId, setDeleteId] = useState<string | null>(null); // State to hold the ID of the firm to be deleted

  const fetchFirms = async () => {
    try {
      setLoading(true);
      const snapshot = await firebase.firestore().collection("firms").get();
      const activeFirms: any = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFirm(activeFirms);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching firms:", error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchFirms();
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onEditRow = (id: any) => {
    navigate(`${PATH.editFirms}/${id}`);
  };

  const onDeleteRow = async () => {
    if (deleteId) {
      try {
        setLoading(true);
        const firmsRef = firebase.firestore().collection("firms").doc(deleteId);
        await firmsRef.delete();
        setLoading(false);
        toast.success("Firm Delete Sucessfully", {
          duration: 4000,
          position: "bottom-right",

          // Styling
          style: {},
          className: "",

          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        });
        fetchFirms();
        confirm.onFalse();
      } catch (error) {
        console.error("Error deleting firm:", error);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Typography variant="h4">Firms</Typography>

        <FirmDialog fetchFirms={fetchFirms} />
      </Box>

      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          marginTop: "30px",
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.isActive ? "True" : "False"}</TableCell>
                      <TableCell
                        align="left"
                        sx={{ margin: "0", padding: "0", whiteSpace: "nowrap" }}
                      >
                        <FirmDialog row={row} fetchFirms={fetchFirms} />
                        <Tooltip title="Delete" placement="top" arrow>
                          <IconButton
                            sx={{ color: "error.main" }}
                            onClick={() => {
                              setDeleteId(row.id); // Set the ID of the firm to be deleted
                              confirm.onTrue();
                            }}
                          >
                            <Icon icon="solar:trash-bin-trash-bold" />
                          </IconButton>
                        </Tooltip>
                        <ConfirmDialog
                          open={confirm.value}
                          onClose={confirm.onFalse}
                          title="Delete"
                          content="Are you sure want to delete?"
                          action={
                            <Button
                              variant="contained"
                              color="error"
                              onClick={onDeleteRow} // Use the onDeleteRow function
                            >
                              Delete
                            </Button>
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default FirmsList;
