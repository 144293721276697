import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from "@mui/material";
import { COLORS } from "../../common/color";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "../../hooks/use-boolean";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import { Icon } from "@iconify/react";
import React from "react";
type Props = {
  row?: any;
  fetchQuestion: any;
};
function SetQuestionDialog({ row, fetchQuestion }: Props) {
  const [loading, setLoading] = useState(false);
  const [openSetQuestionDialog, setOpenSetQuestionDialog] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedShows, setSelectedShows] = useState(false);
  const [category, setCategory] = useState([]);

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setSelectedCategory(event.target.value);
  };
  const handleShowChange = (event: any) => {
    setSelectedShows(event.target.value);
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const fetchCategory = async () => {
    try {
      setLoading(true);
      const snapshot = await firebase
        .firestore()
        .collection("category")
        .where("isActive", "==", true)
        .get();
      const activeCategory: any = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategory(activeCategory);
      setLoading(false);
      // Select the first category by default
      // if (activeFirms.length > 0) {
      //   setSelectedFirm(activeFirms[0].id);
      // }
    } catch (error) {
      console.error("Error fetching firms:", error);
      setLoading(false);
    }
  };

  const fetchQuestions = async () => {
    try {
      const firmsRef = firebase.firestore().collection("questions").doc(row.id);
      const doc: any = await firmsRef.get();
      if (doc.exists) {
        const questionData = doc.data();
        questionData.id = row.id;
        setValue("question", questionData.question);
        setValue("answer", questionData.answer);
        setSelectedCategory(questionData.categoryId);
        setSelectedShows(questionData.isShow || false);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching:", error);
    }
  };
  useEffect(() => {
    fetchCategory();
    if (row) {
      fetchQuestions();
    }
  }, [row, setValue]);

  const onSubmit = async (data: any) => {
    try {
      if (row) {
        const questionRef = firebase
          .firestore()
          .collection("questions")
          .doc(row.id);
        await questionRef.update({
          question: data.question,
          answer: data.answer,
          categoryId: selectedCategory,
          isShow: selectedShows,
        });
        toast.success("Question Upadated Sucessfully", {
          duration: 4000,
          position: "bottom-right",

          // Styling
          style: {},
          className: "",

          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        });
        fetchQuestion();
        setOpenSetQuestionDialog(false);
        setSelectedShows(false);
        setSelectedCategory("");
        reset();
      } else {
        setLoading(true);
        const questionData = {
          question: data.question,
          answer: data.answer,
          categoryId: selectedCategory,
          isShow: selectedShows,
        };

        await firebase.firestore().collection("questions").add(questionData);

        setLoading(false);
        toast.success("Question Added Sucessfully", {
          duration: 4000,
          position: "bottom-right",

          // Styling
          style: {},
          className: "",

          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        });
        setOpenSetQuestionDialog(false);
        setSelectedShows(false);
        setSelectedCategory("");
        reset();
        fetchQuestion();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleClickOpenSetQuestionDilog = () => {
    fetchCategory();
    setOpenSetQuestionDialog(true);
  };

  const handleCloseSetQuestionDialog = () => {
    setOpenSetQuestionDialog(false);
    reset();
    fetchQuestion();
  };

  return (
    <>
      {!row ? (
        <Button
          onClick={handleClickOpenSetQuestionDilog}
          sx={{
            // backgroundColor: COLORS.black,
            width: "fit-content",
            color: "#fff",
            backgroundColor: "#573CFF",
            p: "8px 12px",
            textTransform: "none",
            fontWeight: 500,
            "&:hover": {
              backgroundColor: "#3D66FB",
            },
          }}
        >
          Add Question
        </Button>
      ) : (
        <Tooltip title="Quick Edit" placement="top" arrow>
          <IconButton onClick={handleClickOpenSetQuestionDilog}>
            <Icon icon="solar:pen-bold" />
          </IconButton>
        </Tooltip>
      )}

      <Dialog
        open={openSetQuestionDialog}
        onClose={handleCloseSetQuestionDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {row ? "Edit Question" : "Create Question"}{" "}
          <Button
            onClick={handleCloseSetQuestionDialog}
            sx={{
              backgroundColor: COLORS.white,
              width: "fit-content",
              color: COLORS.black,
              "&:hover": {
                backgroundColor: `${COLORS.black} !important`,
                color: `${COLORS.white} !important`,
              },
            }}
          >
            <CloseIcon />
          </Button>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent sx={{ width: "100%" }}>
            <TextField
              sx={{ mt: 2 }}
              label="Question"
              {...register("question", { required: true })}
              placeholder="Enter Question"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            {errors.question && errors.question.type === "required" && (
              <p className="errorMsg" style={{ color: "red" }}>
                Question is required
              </p>
            )}
            <TextField
              sx={{ mt: 2 }}
              label="Answer"
              {...register("answer", { required: true })}
              placeholder="Enter Answer"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            {errors.answer && errors.answer.type === "required" && (
              <p className="errorMsg" style={{ color: "red" }}>
                Answer is required
              </p>
            )}
            <FormControl sx={{ marginTop: "20px" }} fullWidth>
              <InputLabel id="demo-simple-select-autowidth-label">
                Select Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={selectedCategory}
                label="Select Category"
                onChange={handleCategoryChange}
              >
                {category.map((category: any) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ marginTop: "20px" }} fullWidth>
              <InputLabel id="demo-simple-select-autowidth-label">
                Select Is Shows
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={selectedShows}
                label="Select Is Shows"
                onChange={handleShowChange}
              >
                <MenuItem value={true as any}>True</MenuItem>
                <MenuItem value={false as any}>False</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions sx={{ marginRight: 2 }}>
            <Button
              type="submit"
              sx={{
                backgroundColor: COLORS.black,
                color: COLORS.white,
                "&:hover": {
                  backgroundColor: `${COLORS.black} !important`,
                  color: `${COLORS.white} !important`,
                },
                textTransform: "none",
              }}
            >
              {loading ? (
                <CircularProgress size={22} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
export default SetQuestionDialog;
