import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { COLORS } from "../../common/color";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { ReduxStoreI } from "../../store/redux-store";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import toast from "react-hot-toast";
import {
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";

import currentImg from "../../assets/image/current.png";
import { PLANS } from "../pricing";

function IHomiesPricing() {
  const navigate = useNavigate();
  const db = getFirestore();
  const [loading, setLoading] = useState(false);
  const { payment } = useStoreState((state: ReduxStoreI) => state.Payment);

  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const checkIsCurrentPlan = (PLAN_ID: string) => {
    if (payment?.subscription?.plan?.id === PLAN_ID) {
      return true;
    } else {
      return false;
    }
  };

  const createCheckoutSession = async (price: string) => {
    setLoading(true);
    const idToken = await getAuth()?.currentUser?.getIdToken(true);
    if (idToken) {
      try {
        if (payment?.subscription) {
          const res = await axios.post(
            "https://asia-south1-techmate-753f5.cloudfunctions.net/finetech/create-portal-session",
            {},

            {
              headers: {
                "Content-Type": "application/json",
                Authorization: idToken,
              },
            }
          );
          const url = res?.data?.portalSessionUrl;
          if (url) {
            window.location.href = url;
          }
        } else {
          const res = await axios.post(
            "https://asia-south1-techmate-753f5.cloudfunctions.net/finetech/create-checkout-session",
            {
              price_id: price,
            },

            {
              headers: {
                "Content-Type": "application/json",
                Authorization: idToken,
              },
            }
          );
          const url = res?.data?.url;
          if (url) {
            window.location.href = url;
          }
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        localStorage.setItem("auth", JSON.stringify(user));

        toast.success("Login Sucessfully", {
          duration: 4000,
          position: "bottom-right",

          // Styling
          style: {},
          className: "",

          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        });

        // Check if user email already exists
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        // If user doesn't exist, save in database
        if (!docSnap.exists()) {
          await setDoc(doc(db, "users", user.uid), {
            name: user.displayName,
            email: user.email,
            timestamp: serverTimestamp(),
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 5 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            my: 1,
            py: 4,
          }}
        >
          <Box
            className="pricing-scroll"
            id="pricing-scroll"
            sx={{
              width: "140px",
              borderRadius: 7,
              backgroundColor: "#F3F7FB",
              mt: 1,
            }}
          >
            <Typography
              fontWeight={600}
              sx={{
                textAlign: "center",
                p: 1,

                background:
                  "linear-gradient(271.24deg, #A15EE8 0%, #6D8DFF 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Pricing
            </Typography>
          </Box>
        </Box>
        {/* card */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            gap: { xs: 2, md: 0 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              sx={{
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px;",

                height: "fit-content",
              }}
            >
              <Box sx={{ my: 2 }}>
                {checkIsCurrentPlan(PLANS.one_month) ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Chip label="Current Plan" />
                  </Box>
                ) : (
                  <Box sx={{ py: "17px" }}></Box>
                )}
                <Typography
                  variant="h4"
                  sx={{
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    my: 1,
                    fontFamily: "Be Vietnam Pro",
                  }}
                >
                  Basic{" "}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: 600,
                    gap: 1,
                    my: 1,
                    fontFamily: "Be Vietnam Pro",
                  }}
                >
                  $12{" "}
                  <Typography
                    sx={{ color: "#636363", fontFamily: "Be Vietnam Pro" }}
                  >
                    /Month
                  </Typography>
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "#F5F5F5",
                  p: 2,
                  // height: "200px",
                  borderTop: "1px solid #F5F5F5",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    mt: 2,
                    color: "#636363",
                    fontFamily: "Be Vietnam Pro",
                  }}
                >
                  <CheckIcon sx={{ color: "#3D66FB" }} />
                  Full access to all questions.
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    mt: 1.5,
                    color: "#636363",
                    fontFamily: "Be Vietnam Pro",
                  }}
                >
                  {" "}
                  <CheckIcon sx={{ color: "#3D66FB" }} />
                  Monthly billing, cancel anytime.
                </Typography>

                {loading ? (
                  <Box
                    sx={{ display: "flex", mt: 3, justifyContent: "center" }}
                  >
                    <CircularProgress color="primary" size={20} />
                  </Box>
                ) : (
                  <Button
                    onClick={() => createCheckoutSession(PLANS.one_month)}
                    sx={{
                      color: "#3D66FB",
                      border: "1px solid #3D66FB",
                      fontWeight: 600,
                      mt: 5,
                      width: "100%",
                      borderRadius: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        p: 0.6,
                        fontWeight: 600,
                        textTransform: "none",
                        fontFamily: "Be Vietnam Pro",
                      }}
                    >
                      {" "}
                      {payment?.subscription ? "Modify Plan" : "Get Started"}
                    </Typography>
                  </Button>
                )}
              </Box>
            </Card>
          </Box>
          <Card sx={{ border: "2px solid #3D66FB", width: { md: "380px" } }}>
            <Box sx={{ backgroundColor: "#3D66FB", p: 2 }}>
              <Typography
                sx={{
                  color: "#fff",
                  display: "flex",
                  justifyContent: "center",
                  gap: 1,
                  fontFamily: "Be Vietnam Pro",
                }}
              >
                Essential
                <AutoAwesomeIcon />
              </Typography>
            </Box>
            <Box sx={{ my: 2 }}>
              {checkIsCurrentPlan(PLANS.three_months) ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Chip label="Current Plan" />
                </Box>
              ) : (
                <Box sx={{ py: "17px" }}></Box>
              )}

              <Typography
                variant="h4"
                sx={{
                  display: "flex",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  my: 1,
                  fontFamily: "Be Vietnam Pro",
                }}
              >
                Save 33%{" "}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  display: "flex",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: 600,
                  gap: 1,
                  my: 1,
                  fontFamily: "Be Vietnam Pro",
                }}
              >
                $8{" "}
                <Typography
                  sx={{ color: "#636363", fontFamily: "Be Vietnam Pro" }}
                >
                  /3 Month
                </Typography>
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "#F5F5F5",
                p: 2,
                // height: "200px",
                borderTop: "1px solid #F5F5F5",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  mt: 2,
                  color: "#636363",
                  fontFamily: "Be Vietnam Pro",
                }}
              >
                <CheckIcon sx={{ color: "#3D66FB" }} />
                Save 33% over monthly plan.
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  mt: 2,
                  color: "#636363",
                  fontFamily: "Be Vietnam Pro",
                }}
              >
                <CheckIcon sx={{ color: "#3D66FB" }} />
                $8/month, $24 every 3 months.
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  mt: 2,
                  color: "#636363",
                  fontFamily: "Be Vietnam Pro",
                }}
              >
                <CheckIcon sx={{ color: "#3D66FB" }} />
                Full access to all questions.
              </Typography>

              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  mt: 1.5,
                  color: "#636363",
                  fontFamily: "Be Vietnam Pro",
                }}
              >
                {" "}
                <CheckIcon sx={{ color: "#3D66FB" }} />
                Quarterly billing, cancel anytime.
              </Typography>

              {loading ? (
                <Box sx={{ display: "flex", mt: 3, justifyContent: "center" }}>
                  <CircularProgress color="primary" size={20} />
                </Box>
              ) : (
                <Button
                  onClick={() => createCheckoutSession(PLANS.three_months)}
                  sx={{
                    color: "#fff",
                    border: "1px solid #3D66FB",
                    fontWeight: 600,
                    mt: 5,
                    width: "100%",
                    borderRadius: 3,
                    backgroundColor: "#3D66FB",
                    "&:hover": {
                      backgroundColor: "#3D66FB",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      p: 0.6,
                      fontWeight: 600,
                      textTransform: "none",
                      fontFamily: "Be Vietnam Pro",
                    }}
                  >
                    {" "}
                    {payment?.subscription ? "Modify Plan" : "Get Started"}
                  </Typography>
                </Button>
              )}
            </Box>
          </Card>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              sx={{
                boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px;",
                height: "fit-content",
              }}
            >
              <Box sx={{ my: 2 }}>
                {checkIsCurrentPlan(PLANS.six_month) ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Chip label="Current Plan" />
                  </Box>
                ) : (
                  <Box sx={{ py: "17px" }}></Box>
                )}

                <Typography
                  variant="h4"
                  sx={{
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    my: 1,
                    fontFamily: "Be Vietnam Pro",
                  }}
                >
                  Save 40%{" "}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    display: "flex",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: 600,
                    gap: 1,
                    my: 1,
                    fontFamily: "Be Vietnam Pro",
                  }}
                >
                  $7{" "}
                  <Typography
                    sx={{ color: "#636363", fontFamily: "Be Vietnam Pro" }}
                  >
                    /6 Month
                  </Typography>
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "#F5F5F5",
                  p: 2,
                  // height: "200px",
                  borderTop: "1px solid #F5F5F5",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    mt: 2,
                    color: "#636363",
                    fontFamily: "Be Vietnam Pro",
                  }}
                >
                  <CheckIcon sx={{ color: "#3D66FB" }} />
                  Save 40% over monthly plan.
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    mt: 1.5,
                    color: "#636363",
                    fontFamily: "Be Vietnam Pro",
                  }}
                >
                  {" "}
                  <CheckIcon sx={{ color: "#3D66FB" }} />
                  $7/month, $42 every 6 months.
                </Typography>

                {loading ? (
                  <Box
                    sx={{ display: "flex", mt: 3, justifyContent: "center" }}
                  >
                    <CircularProgress color="primary" size={20} />
                  </Box>
                ) : (
                  <Button
                    onClick={() => createCheckoutSession(PLANS.six_month)}
                    sx={{
                      color: "#3D66FB",
                      border: "1px solid #3D66FB",
                      fontWeight: 600,
                      mt: 5,
                      width: "100%",
                      borderRadius: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        p: 0.6,
                        fontWeight: 600,
                        textTransform: "none",
                        fontFamily: "Be Vietnam Pro",
                      }}
                    >
                      {" "}
                      {payment?.subscription ? "Modify Plan" : "Get Started"}
                    </Typography>
                  </Button>
                )}
              </Box>
            </Card>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default IHomiesPricing;
