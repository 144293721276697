import { Container } from "@mui/material";
import FirmsList from "../../component/firmsList";
import CategoryList from "../../component/categoryList";
import SetQuestionList from "../../component/setQuestionList";
import UserList from "../../component/userList";

function Admin() {
  return (
    <>
      <Container maxWidth="lg">
        <FirmsList />
        <CategoryList />
        <SetQuestionList />
        <UserList />
      </Container>
    </>
  );
}
export default Admin;
