import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Papa from "papaparse";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import { Icon } from "@iconify/react";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "../../custom-dialog/confirm-dialog";
import { useBoolean } from "../../hooks/use-boolean";
import toast from "react-hot-toast";
import SetQuestionDialog from "../setQuestionDialog";
import { COLORS } from "../../common/color";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
interface Column {
  id: "question" | "answer" | "freetrial" | "action";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "question", label: "Question", minWidth: 170 },
  { id: "answer", label: "Answer", minWidth: 100 },
  { id: "freetrial", label: "Free Trial", minWidth: 100 },
  {
    id: "action",
    label: "Action",
  },
];

interface Data {
  question: string;
  answer: string;
}

interface CsvRow {
  categoryId: any;
  question: string;
  answer: string;
}
function createData(question: string, answer: string): Data {
  return { question, answer };
}
interface CsvRowWithIndex extends CsvRow {
  index: number;
}

function SetQuestionList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [question, setQuestion] = React.useState([]);
  const rows: any[] = question;
  const [loading, setLoading] = useState(false);

  const confirm = useBoolean();

  const [deleteId, setDeleteId] = useState<string | null>(null); // State to hold the ID of the firm to be deleted
  const [openBulkUploadDialog, setOpenBulkUploadDialog] = useState(false);

  const handleClickOpenBulkUploadDilog = () => {
    setOpenBulkUploadDialog(true);
  };
  const handleCloseBulkUploadDialog = () => {
    setOpenBulkUploadDialog(false);
    setUnmatchedRows([]);
    setUnmatchedCount(0);
    setMatchedCount(0);
  };

  const [unmatchedRows, setUnmatchedRows] = useState<CsvRowWithIndex[]>([]);
  const [matchedCount, setMatchedCount] = useState<number>(0);
  const [unmatchedCount, setUnmatchedCount] = useState<number>(0);
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file && file.type === "text/csv") {
      Papa.parse(file, {
        header: true,
        complete: async (results: any) => {
          let newArray = results.data.slice(0, -1);
          console.log("Parsed CSV data:", newArray);
          await validateCategoryIds(newArray);
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
        },
      });
    } else {
      alert("Please upload a valid CSV file.");
    }
  };

  // const processCSV = (str: string): CsvRowWithIndex[] => {
  //   const rows = str.split(/\r?\n/); // Split the input string into rows

  //   const arrayData: CsvRowWithIndex[] = rows.slice(1).map((row, index) => {
  //     const values = [];
  //     let inQuotes = false;
  //     let value = "";

  //     for (let char of row) {
  //       if (char === '"') {
  //         inQuotes = !inQuotes;
  //       } else if (char === "," && !inQuotes) {
  //         values.push(value.trim());
  //         value = "";
  //       } else {
  //         value += char;
  //       }
  //     }
  //     values.push(value.trim()); // Add the last value

  //     return {
  //       index: index + 2, // Adding 2 to account for the 1-based index and header row
  //       categoryId: values[0]?.trim(),
  //       question: values[1]?.trim() || "",
  //       answer: values[2]?.trim() || "",
  //     };
  //   });

  //   return arrayData;
  // };

  const validateCategoryIds = async (arrayData: CsvRow[]) => {
    try {
      const categoriesSnapshot = await firebase
        .firestore()
        .collection("category")
        .get();
      const categoryIds = categoriesSnapshot.docs.map((doc) => doc.id);

      const unmatched: any = arrayData.filter(
        (item) => !categoryIds.includes(item.categoryId)
      );
      const unmatchedWithIndex = unmatched.map((item: any, index: any) => ({
        ...item,
        index: index + 2,
      }));

      setUnmatchedRows(unmatchedWithIndex);
      setUnmatchedCount(unmatchedWithIndex.length);

      const matched = arrayData.filter((item) =>
        categoryIds.includes(item.categoryId)
      );

      setMatchedCount(matched.length);
      await insertMatchedRows(matched);
    } catch (error) {
      console.error("Error validating category IDs:", error);
    }
  };
  const insertMatchedRows = async (matchedRows: CsvRow[]) => {
    try {
      const batch = firebase.firestore().batch();
      matchedRows.forEach((row) => {
        const docRef = firebase.firestore().collection("questions").doc(); // Automatically generate unique ID
        batch.set(docRef, {
          categoryId: row.categoryId,
          question: row.question,
          answer: row.answer,
        });
      });
      await batch.commit();
      toast.success("Question Upadated Sucessfully", {
        duration: 4000,
        position: "bottom-right",

        // Styling
        style: {},
        className: "",

        // Change colors of success/error/loading icon
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
      });
      console.log(
        "Matched rows have been inserted into the question collection."
      );
    } catch (error) {
      console.error(
        "Error inserting matched rows into the question collection:",
        error
      );
    }
  };
  const fetchQuestion = async () => {
    try {
      setLoading(true);
      const snapshot = await firebase.firestore().collection("questions").get();
      const question: any = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setQuestion(question);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching question:", error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchQuestion();
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const convertToCSV = (jsonData: any) => {
    const headers = Object.keys(jsonData[0]);
    const csvRows = [];

    // Create the header row
    csvRows.push(headers.join(","));

    // Create data rows
    jsonData.forEach((row: any) => {
      const values = headers.map((header) => {
        const escapedValue = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escapedValue}"`;
      });
      csvRows.push(values.join(","));
    });

    return csvRows.join("\n");
  };

  const downloadCSV = () => {
    const csvData = convertToCSV(question);

    // Create a Blob from the CSV data
    const blob = new Blob([csvData], { type: "text/csv" });

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "data.csv";

    // Append the link to the document and trigger download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onDeleteRow = async () => {
    if (deleteId) {
      try {
        setLoading(true);
        const firmsRef = firebase
          .firestore()
          .collection("questions")
          .doc(deleteId);
        await firmsRef.delete();
        toast.success("Question Delete Sucessfully", {
          duration: 4000,
          position: "bottom-right",

          // Styling
          style: {},
          className: "",

          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        });
        setLoading(false);
        fetchQuestion();
        confirm.onFalse();
      } catch (error) {
        console.error("Error deleting firm:", error);
        setLoading(false);
      }
    }
  };
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `${process.env.PUBLIC_URL}/demo.csv`; // Reference to the file in the public folder
    link.download = "demo.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Typography variant="h4">Questions</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            gap: 2,
          }}
        >
          {/* <Button
            onClick={downloadCSV}
            sx={{
              // backgroundColor: COLORS.black,
              width: "fit-content",
              color: "#fff",
              backgroundColor: "#573CFF",
              p: "8px 12px",
              textTransform: "none",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "#3D66FB",
              },
            }}
          >
            Export
          </Button> */}
          <Button
            onClick={handleClickOpenBulkUploadDilog}
            sx={{
              // backgroundColor: COLORS.black,
              width: "fit-content",
              color: "#fff",
              backgroundColor: "#573CFF",
              p: "8px 12px",
              textTransform: "none",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "#3D66FB",
              },
            }}
          >
            + Bulk Upload
          </Button>
          <SetQuestionDialog fetchQuestion={fetchQuestion} />
        </Box>
      </Box>
      <Dialog
        open={openBulkUploadDialog}
        onClose={handleCloseBulkUploadDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          Bulk Upload Question
          <Button
            onClick={handleCloseBulkUploadDialog}
            sx={{
              backgroundColor: COLORS.white,
              width: "fit-content",
              color: COLORS.black,
              "&:hover": {
                backgroundColor: `${COLORS.black} !important`,
                color: `${COLORS.white} !important`,
              },
            }}
          >
            <CloseIcon />
          </Button>
        </DialogTitle>

        <DialogContent
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            // alignItems: "center",
            // height: "50px",
          }}
        >
          <Box>
            <Box
              sx={{
                p: "10px",
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderTop: "1px solid black",

                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
              }}
            >
              <Typography fontWeight={600}>1. Upload</Typography>
            </Box>

            <Box
              sx={{
                p: "20px",
                border: "1px solid black",
                // borderRadius: 1,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            >
              Choose the file to be import:{" "}
              <input
                type="file"
                accept=".csv"
                style={{
                  backgroundColor: COLORS.black,
                  color: COLORS.white,
                }}
                onChange={handleFileUpload}
              />
              <Button
                sx={{ mt: "10px" }}
                variant="outlined"
                color="success"
                startIcon={<SimCardDownloadIcon />}
                onClick={handleDownload}
              >
                Sample File
              </Button>
            </Box>
          </Box>

          {(matchedCount > 0 || unmatchedCount > 0) && (
            <>
              <Box sx={{ marginTop: "20px" }}>
                <Box
                  sx={{
                    p: "10px",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    borderTop: "1px solid black",

                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                  }}
                >
                  <Typography fontWeight={600}>2. Summary</Typography>
                </Box>
                <Box
                  sx={{
                    p: "20px",
                    border: "1px solid black",
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                  }}
                >
                  <Typography>
                    Total : {matchedCount + unmatchedCount}
                  </Typography>
                  <Typography>Success : {matchedCount}</Typography>
                  <Typography>Error : {unmatchedCount}</Typography>

                  {unmatchedRows.length > 0 && (
                    <>
                      <Typography sx={{ marginTop: "30px" }} fontWeight={600}>
                        CSV File Summary:
                      </Typography>
                      <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left" style={{ minWidth: 170 }}>
                                Row Id
                              </TableCell>
                              <TableCell>Details</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {unmatchedRows.map((row, index) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={index}
                              >
                                <TableCell>{row?.index}</TableCell>
                                <TableCell>
                                  Category ID: {row.categoryId} - does not match
                                  with database categoryId.
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                </Box>
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ marginRight: 2 }}>
          <Button
            sx={{
              backgroundColor: COLORS.black,
              color: COLORS.white,
              "&:hover": {
                backgroundColor: `${COLORS.black} !important`,
                color: `${COLORS.white} !important`,
              },
              textTransform: "none",
            }}
            onClick={handleCloseBulkUploadDialog}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell>{row.question}</TableCell>
                      <TableCell>{row.answer}</TableCell>
                      <TableCell>{row.isShow ? "Yes" : "No" || "No"}</TableCell>
                      <TableCell
                        align="left"
                        sx={{ margin: "0", padding: "0", whiteSpace: "nowrap" }}
                      >
                        <SetQuestionDialog
                          row={row}
                          fetchQuestion={fetchQuestion}
                        />

                        <Tooltip title="Delete" placement="top" arrow>
                          <IconButton
                            sx={{ color: "error.main" }}
                            onClick={() => {
                              setDeleteId(row.id); // Set the ID of the firm to be deleted
                              confirm.onTrue();
                            }}
                          >
                            <Icon icon="solar:trash-bin-trash-bold" />
                          </IconButton>
                        </Tooltip>
                        <ConfirmDialog
                          open={confirm.value}
                          onClose={confirm.onFalse}
                          title="Delete"
                          content="Are you sure want to delete?"
                          action={
                            <Button
                              variant="contained"
                              color="error"
                              onClick={onDeleteRow} // Use the onDeleteRow function
                            >
                              Delete
                            </Button>
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default SetQuestionList;
