import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/iHomies-img/logo.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import { PATH } from "../../constant";

function IHomiesFooter() {
  const navigate = useNavigate();
  const goToHome = () => {
    navigate(PATH.home);
  };
  const goToPrivacyPolicy = () => {
    navigate(PATH.termsofservice);
  };
  return (
    <Box sx={{ bgcolor: "#F3F7FB" }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            width: "100%",
            padding: "24px 0px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "70%",
                }}
              >
                <Box
                  onClick={goToHome}
                  component="img"
                  sx={{
                    width: "200px",
                    cursor: "pointer",
                  }}
                  src={logo}
                />
                <Typography variant="body2" sx={{ fontFamily: "Poppins" }}>
                  Real interview questions from top IB and PE interviews,
                  sourced weekly from students.
                </Typography>

                {/* <Box sx={{ display: "flex", gap: 2 }}>
                  <FacebookIcon />
                  <TwitterIcon />
                  <InstagramIcon />
                </Box> */}

                <Typography
                  variant="body2"
                  sx={{ color: "#485687", fontFamily: "Poppins" }}
                >
                  FinancePrep ● Copyright © 2024
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "70%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 600, fontFamily: "Poppins" }}
                >
                  Pages
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 2,
                    gap: 1,
                  }}
                >
                  <Link
                    onClick={() => {
                      navigate(
                        {
                          pathname: PATH.home,
                        },
                        {
                          state: {
                            scroll: true,
                          },
                        }
                      );
                    }}
                    to={"why-us-scroll"}
                    offset={-100}
                    spy
                    smooth
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#485687",
                        cursor: "pointer",
                        fontFamily: "Poppins",
                      }}
                    >
                      Why Us?
                    </Typography>{" "}
                  </Link>

                  <Link
                    onClick={() => {
                      navigate(
                        {
                          pathname: PATH.home,
                        },
                        {
                          state: {
                            scroll: true,
                          },
                        }
                      );
                    }}
                    to={"why-us-scroll"}
                    offset={-100}
                    spy
                    smooth
                  >
                    {" "}
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#485687",
                        cursor: "pointer",
                        fontFamily: "Poppins",
                      }}
                    >
                      What Makes Us Unique
                    </Typography>
                  </Link>

                  {/* <Typography variant="body2" sx={{ color: "#485687" }}>
                    Question Bank
                  </Typography>

                  <Typography variant="body2" sx={{ color: "#485687" }}>
                    About Us
                  </Typography> */}
                  <Link
                    onClick={() => {
                      navigate(
                        {
                          pathname: PATH.testimonials,
                        },
                        {
                          state: {
                            scroll: true,
                          },
                        }
                      );
                    }}
                    to={"testimonials-scroll"}
                    offset={-100}
                    spy
                    smooth
                  >
                    {" "}
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#485687",
                        cursor: "pointer",
                        fontFamily: "Poppins",
                      }}
                    >
                      Testimonials
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "70%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 600, fontFamily: "Poppins" }}
                >
                  Reach Us & Terms of use
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 2,
                    gap: 1,
                  }}
                >
                  <Typography
                    component={"a"}
                    href="mailto:info@financeprep.io"
                    variant="body2"
                    sx={{
                      color: "#485687",
                      cursor: "pointer",
                      textDecoration: "none",
                      fontFamily: "Poppins",
                    }}
                  >
                    Contact Us
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#485687",
                      cursor: "pointer",
                      fontFamily: "Poppins",
                    }}
                    onClick={goToPrivacyPolicy}
                  >
                    Terms and Condition
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default IHomiesFooter;
