// src/contexts/AuthContext.js
import React, { createContext, useContext } from "react";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import {
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
  getFirestore,
} from "firebase/firestore";
import toast from "react-hot-toast";
import "firebase/compat/firestore";
import firebase from "firebase/compat/app"; // Adjust the import according to your project structure

const AuthContext = createContext({
  onGoogleClick: async () => {},
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: any) => {
  const db = getFirestore();
  const onGoogleClick = async () => {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      localStorage.setItem("auth", JSON.stringify(user));
      toast.success("Login Successfully", {
        duration: 4000,
        position: "bottom-right",
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
      });

      // Check if user email already exists
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);

      // If user doesn't exist, save in database
      if (!docSnap.exists()) {
        await setDoc(doc(db, "users", user.uid), {
          name: user.displayName,
          email: user.email,
          timestamp: serverTimestamp(),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthContext.Provider value={{ onGoogleClick }}>
      {children}
    </AuthContext.Provider>
  );
};
