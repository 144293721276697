import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { COLORS } from "../../common/color";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { ReduxStoreI } from "../../store/redux-store";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import toast from "react-hot-toast";
import {
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";

import currentImg from "../../assets/image/current.png";

export const PLANS = {
  one_month: "price_1PEGSzEQunFg9bcFmcfDzUZC",
  three_months: "price_1PEGUFEQunFg9bcFrMbJBYdR",
  six_month: "price_1PEGUQEQunFg9bcF2kSNhbwt",
};
function Pricing() {
  const navigate = useNavigate();
  const db = getFirestore();
  const [loading, setLoading] = useState(false);
  const { payment } = useStoreState((state: ReduxStoreI) => state.Payment);

  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const checkIsCurrentPlan = (PLAN_ID: string) => {
    if (payment?.subscription?.plan?.id === PLAN_ID) {
      return true;
    } else {
      return false;
    }
  };

  const createCheckoutSession = async (price: string) => {
    setLoading(true);
    const idToken = await getAuth()?.currentUser?.getIdToken(true);
    if (idToken) {
      try {
        if (payment?.subscription) {
          const res = await axios.post(
            "https://asia-south1-techmate-753f5.cloudfunctions.net/finetech/create-portal-session",
            {},

            {
              headers: {
                "Content-Type": "application/json",
                Authorization: idToken,
              },
            }
          );
          const url = res?.data?.portalSessionUrl;
          if (url) {
            window.location.href = url;
          }
        } else {
          const res = await axios.post(
            "https://asia-south1-techmate-753f5.cloudfunctions.net/finetech/create-checkout-session",
            {
              price_id: price,
            },

            {
              headers: {
                "Content-Type": "application/json",
                Authorization: idToken,
              },
            }
          );
          const url = res?.data?.url;
          if (url) {
            window.location.href = url;
          }
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        localStorage.setItem("auth", JSON.stringify(user));

        toast.success("Login Sucessfully", {
          duration: 4000,
          position: "bottom-right",

          // Styling
          style: {},
          className: "",

          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        });

        // Check if user email already exists
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        // If user doesn't exist, save in database
        if (!docSnap.exists()) {
          await setDoc(doc(db, "users", user.uid), {
            name: user.displayName,
            email: user.email,
            timestamp: serverTimestamp(),
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      <Container maxWidth={"lg"}>
        <Box
          className="pricing-scroll"
          id="pricing-scroll"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            // minHeight: "100vh",
            marginTop: "100px",
            alignItems: "center",
            gap: 2,
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: { md: "690px", lg: "608px" },
              textAlign: "center",
              gap: 3,
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: 700, fontSize: "2.4 rem" }}
            >
              Pricing and Plans
            </Typography>
            {/* <Typography
              variant="body2"
              sx={{ marginTop: "20px", fontStyle: "italic" }}
            >
              "I walked into my investment banking interview feeling
              well-prepared and in control, thanks to Overlevered."
            </Typography> */}
          </Box>
          <Box
            sx={{
              mt: 5,
              display: "flex",
              justifyContent: "center",
              width: "100%",
              mx: "auto",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Grid container spacing={{ xs: 2, md: 9 }}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {checkIsCurrentPlan(PLANS.one_month) ? (
                    <Box
                      component="img"
                      src={currentImg}
                      sx={{ width: "30%", py: 1 }}
                    />
                  ) : (
                    <Box sx={{ height: "53px" }}></Box>
                  )}

                  <Card
                    sx={{
                      backgroundColor: COLORS.white,
                      height: "350px",
                      border: "3px solid videoBackground",
                      width: "290px",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: COLORS.videoBackground,

                        width: "100%",
                        height: "12%",
                      }}
                    >
                      {" "}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        mt: 3,
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        1 Month
                      </Typography>{" "}
                      <Typography
                        variant="h2"
                        sx={{
                          fontWeight: 600,
                          marginTop: "30px",
                          display: "flex",
                          alignItems: "flex-end",
                          m: " 0 !inmportant",
                        }}
                      >
                        $12
                        <Typography sx={{ m: " 0 0 8px 5px !important" }}>
                          /month
                        </Typography>
                      </Typography>{" "}
                      <Typography
                        sx={{
                          marginTop: "30px",
                          color: COLORS.priceGray,
                          fontSize: "20px",
                        }}
                      >
                        $12 total
                      </Typography>
                      {loading ? (
                        <Box sx={{ display: "flex", mt: 3 }}>
                          <CircularProgress color="primary" size={20} />
                        </Box>
                      ) : (
                        <Button
                          onClick={() => createCheckoutSession(PLANS.one_month)}
                          sx={{
                            backgroundColor: `${COLORS.gray} !important`,
                            color: `${COLORS.white} !important`,
                            "&:hover": {
                              backgroundColor: `${COLORS.black} !important`,
                              color: `${COLORS.white} !important`,
                            },
                            // fontSize: "12px",
                            marginTop: "20px",
                            width: "80%",
                            borderRadius: 2,

                            textTransform: "none",
                          }}
                        >
                          {/* {checkIsCurrentPlan(PLANS.one_month) && "Current"} */}
                          {payment?.subscription
                            ? "Modify Plan"
                            : "Get Started"}
                        </Button>
                      )}
                      {/* <Typography variant="h5">hello</Typography>{" "} */}
                    </Box>
                  </Card>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {checkIsCurrentPlan(PLANS.three_months) ? (
                    <Box
                      component="img"
                      src={currentImg}
                      sx={{ width: "30%", py: 1 }}
                    />
                  ) : (
                    <Box sx={{ height: "53px" }}></Box>
                  )}
                  <Card
                    sx={{
                      backgroundColor: COLORS.white,
                      height: "350px",
                      width: "290px",

                      border: "3px solid black",
                    }}
                  >
                    {" "}
                    <Box
                      sx={{
                        backgroundColor: COLORS.black,
                        height: "12%",
                        width: "100%",
                        p: 1,
                      }}
                    >
                      {" "}
                      <Typography
                        sx={{
                          color: COLORS.white,
                        }}
                      >
                        {" "}
                        Save 33%
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        mt: 3,
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        3 Month
                      </Typography>{" "}
                      <Typography
                        variant="h2"
                        sx={{
                          fontWeight: 600,
                          marginTop: "30px",
                          display: "flex",
                          alignItems: "flex-end",
                          m: " 0 !inmportant",
                        }}
                      >
                        $8
                        <Typography sx={{ m: " 0 0 8px 5px !important" }}>
                          /month
                        </Typography>
                      </Typography>{" "}
                      <Typography
                        variant="body2"
                        sx={{
                          marginTop: "30px",
                          color: COLORS.priceGray,
                          fontSize: "20px",
                        }}
                      >
                        $24 total
                      </Typography>{" "}
                      {loading ? (
                        <Box sx={{ display: "flex", mt: 3 }}>
                          <CircularProgress color="primary" size={20} />
                        </Box>
                      ) : (
                        <Button
                          onClick={() =>
                            createCheckoutSession(PLANS.three_months)
                          }
                          sx={{
                            backgroundColor: `${COLORS.gray} !important`,
                            color: `${COLORS.white} !important`,
                            "&:hover": {
                              backgroundColor: `${COLORS.black} !important`,
                              color: `${COLORS.white} !important`,
                            },
                            // fontSize: "12px",
                            marginTop: "20px",
                            width: "80%",
                            borderRadius: 2,

                            textTransform: "none",
                          }}
                        >
                          {" "}
                          {/* {checkIsCurrentPlan(PLANS.three_months) && "Current"} */}
                          {payment?.subscription
                            ? "Modify Plan"
                            : "Get Started"}
                        </Button>
                      )}
                      {/* <Typography variant="h5">/month</Typography>{" "} */}
                      {/* <Typography variant="h5">hello</Typography>{" "} */}
                    </Box>
                  </Card>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {checkIsCurrentPlan(PLANS.six_month) ? (
                    <Box
                      component="img"
                      src={currentImg}
                      sx={{ width: "30%", py: 1 }}
                    />
                  ) : (
                    <Box sx={{ height: "53px" }}></Box>
                  )}
                  <Card
                    sx={{
                      backgroundColor: COLORS.white,
                      height: "350px",
                      width: "290px",
                      border: "3px solid videoBackground",
                    }}
                  >
                    {" "}
                    <Box
                      sx={{
                        backgroundColor: COLORS.videoBackground,
                        height: "12%",
                        width: "100%",
                        p: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          color: COLORS.black,
                        }}
                      >
                        {" "}
                        Save 40%
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        mt: 3,
                      }}
                    >
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        6 Month
                      </Typography>{" "}
                      <Typography
                        variant="h2"
                        sx={{
                          fontWeight: 600,
                          marginTop: "30px",
                          display: "flex",
                          alignItems: "flex-end",
                          m: " 0 !inmportant",
                        }}
                      >
                        $7
                        <Typography sx={{ m: " 0 0 8px 5px !important" }}>
                          /month
                        </Typography>
                      </Typography>{" "}
                      <Typography
                        variant="body2"
                        sx={{
                          marginTop: "30px",
                          color: COLORS.priceGray,
                          fontSize: "20px",
                        }}
                      >
                        $42 total
                      </Typography>{" "}
                      {loading ? (
                        <Box sx={{ display: "flex", mt: 3 }}>
                          <CircularProgress color="primary" size={20} />
                        </Box>
                      ) : (
                        <Button
                          onClick={() => createCheckoutSession(PLANS.six_month)}
                          sx={{
                            backgroundColor: `${COLORS.gray} !important`,
                            color: `${COLORS.white} !important`,
                            "&:hover": {
                              backgroundColor: `${COLORS.black} !important`,
                              color: `${COLORS.white} !important`,
                            },
                            // fontSize: "12px",
                            marginTop: "20px",
                            width: "80%",
                            borderRadius: 2,

                            textTransform: "none",
                          }}
                        >
                          {/* {checkIsCurrentPlan(PLANS.six_month) && "Current "} */}
                          {payment?.subscription
                            ? "Modify Plan"
                            : "Get Started"}
                        </Button>
                      )}
                      {/* <Typography variant="h5">/month</Typography>{" "} */}
                      {/* <Typography variant="h5">hello</Typography>{" "} */}
                    </Box>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Pricing;
