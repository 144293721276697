import { Box, Button, Typography, Container } from "@mui/material";
import React from "react";

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useAuth } from "../../context/AuthContext";

function IHomiesHero() {
  const { onGoogleClick } = useAuth();
  return (
    <Box sx={{ backgroundColor: "#F3F7FB" }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            py: 10,
          }}
        >
          <Box
            sx={{
              border: "1px solid #000",
              backgroundColor: "#000",
              borderRadius: 7,
              p: "4px",
              width: "200px",
            }}
          >
            <Box
              sx={{
                borderRadius: 7,
                border: "1px solid #fff",
                backgroundColor: "#fff",
                // width: "80%",
                p: 0.1,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  background:
                    "linear-gradient(271.22deg, #2100EE 0%, #6378C2 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontFamily: "Be Vietnam Pro",
                }}
              >
                Finance Question Bank
              </Typography>
            </Box>
          </Box>{" "}
          <Typography
            variant="h2"
            sx={{
              color: "#02015A",
              fontWeight: 600,
              px: { xs: 0, sm: 10, md: 15 },
              fontSize: { xs: "2rem", md: "3.7rem" },
              fontFamily: "Be Vietnam Pro",
            }}
          >
            Demystifying the intimidating finance interview
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#4E5774",
              px: { xs: 0, sm: 20, md: 35 },
              fontSize: { xs: "1rem", md: "1rem" },
              fontFamily: "Be Vietnam Pro",
            }}
          >
            Real Interview Questions asked in Top IB and PE interviews. All
            questions are sourced real time from students on the ground taking
            interviews weekly.
          </Typography>
          <Button
            onClick={onGoogleClick}
            sx={{
              height: "50px",
              width: "fit-content",
              // width: "130px",
              color: "#fff",
              backgroundColor: "#573CFF",
              p: "8px 12px",
              textTransform: "none",
              my: 2,
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "#3D66FB",
              },
              fontFamily: "Be Vietnam Pro",
            }}
          >
            Start Prep
            <ArrowOutwardIcon sx={{ ml: 1 }} fontSize="small" />
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default IHomiesHero;
