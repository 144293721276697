import { Box, Typography, Button, Container, styled } from "@mui/material";
import logo from "../../assets/iHomies-img/logo.png";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../constant";
function IHomiesUnique() {
  const navigate = useNavigate();
  const goToHome = () => {
    navigate(PATH.home);
  };
  const GradientText = styled(Typography)(({ theme }) => ({
    background: "linear-gradient(272.08deg, #A15EE8 0%, #6D8DFF 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textAlign: "center",
  }));
  return (
    <Container maxWidth="lg">
      <Box
        className="why-us-scroll"
        id="why-us-scroll"
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
          mb: 5,
          mt: 15,
          textAlign: "center",
        }}
      >
        <Box
          onClick={goToHome}
          component="img"
          sx={{
            width: "150px",
            cursor: "pointer",
          }}
          src={logo}
        ></Box>

        <Typography
          variant="h3"
          sx={{
            color: "#02015A",
            fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem" },
            fontWeight: 500,
            fontFamily: "Be Vietnam Pro",
          }}
        >
          What makes us unique?
        </Typography>
        <Typography
          sx={{
            color: "#68708C",
            px: { xs: 0, sm: 20, md: 35 },
            fontSize: { xs: "1rem", md: "1rem" },
            mt: 2,
            fontFamily: "Be Vietnam Pro",
          }}
        >
          User-friendly Interface, Real-Time Data Collection, Broad Bank &
          Industry Group Coverage
        </Typography>
      </Box>
    </Container>
  );
}

export default IHomiesUnique;
