import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import React from "react";

// import img1 from "../../assets/iHomies-img/t1.png";
// import img2 from "../../assets/iHomies-img/t2.png";
// import img3 from "../../assets/iHomies-img/t3.png";
// import img4 from "../../assets/iHomies-img/t4.png";
// import img5 from "../../assets/iHomies-img/t5.png";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { COLORS } from "../../common/color";
import { Margin } from "@mui/icons-material";
function IHomiesTestimonials() {
  // const testimonials = [
  //   {
  //     heading:
  //       '"I struggled to pass the interview questions for a financial institution, but thanks to Finance Prep, I became a top-notch candidate who met their requirements perfectly."',
  //     img: img1,
  //     author: "John Smith, Junior Accountant",
  //     bgColor: "#ECE5FF",
  //   },
  //   {
  //     heading:
  //       "The interview questions were intense, but Finance Prep's guidance was spot-on. I emerged as a leading candidate, thanks to their excellent preparation tools.",
  //     img: img2,
  //     author: "Sarah Johnson, Senior accountant at Z",
  //     bgColor: "#FBF7B8",
  //   },
  //   {
  //     heading:
  //       '"I struggled to pass the interview questions for a financial institution, but thanks to Finance Prep, I became a top-notch candidate who met their requirements perfectly."',
  //     img: img1,
  //     author: "John Smith, Junior Accountant",
  //     bgColor: "#ECE5FF",
  //   },
  //   {
  //     heading:
  //       "The interview questions were intense, but Finance Prep's guidance was spot-on. I emerged as a leading candidate, thanks to their excellent preparation tools.",
  //     img: img2,
  //     author: "Sarah Johnson, Senior accountant at Z",
  //     bgColor: "#FBF7B8",
  //   },
  // ];

  return (
    <Box sx={{ backgroundColor: "#EDF2F7", py: 5, my: 10 }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "170px",
              borderRadius: 7,
              backgroundColor: "#F3F7FB",
              my: 2,
            }}
          >
            <Typography
              fontWeight={600}
              sx={{
                textAlign: "center",
                borderRadius: 7,
                width: "170px",
                p: 0.5,

                backgroundColor: "#FFF",
                background:
                  "linear-gradient(271.24deg, #A15EE8 0%, #6D8DFF 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontFamily: "Be Vietnam Pro",
              }}
            >
              TESTIMONIALS
            </Typography>
          </Box>
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
              mb: 4,
              color: "#02015A",
              fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem" },
              fontWeight: 500,
              fontFamily: "Be Vietnam Pro",
            }}
            className="testimonials-scroll"
            id="testimonials-scroll"
          >
            Don’t take our word for it
          </Typography>
          <Box sx={{ width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} spacing={2} sm={6} md={4}>
                <Card sx={{ maxWidth: 345, padding: 2, borderRadius: 4 }}>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Avatar />
                      </Grid>

                      <Grid item alignItems="end" justifyContent="flex-end">
                        <Rating
                          value={5}
                          readOnly
                          sx={{ color: COLORS.starColor }}
                        />
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        "Honestly, I can’t believe how lucky I got finding
                        FinancePrep on Reddit while prepping for my investment
                        banking interviews. Going to a non-target school, I
                        didn’t have access to the same resources as others and
                        the process felt overwhelming. I literally made it to
                        one interview and I knew I had to ace it on the
                        technical end. <br></br>
                        On Finance Prep, instead of scrambling to gather random
                        resources, I had everything I needed in one place. The
                        question bank was packed with real interview questions
                        that you cannot get unless you’re in a finance club in a
                        target school. I also love how the interface is set up
                        like flashcards—it made practicing super easy and even
                        kinda fun! I could go through questions on my own time,
                        and I saw real progress. I would recommend this for any
                        non-target students to get on the level playing field
                        with the target kids.
                      </Typography>
                    </Box>
                    <Grid item xs mt={2}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Be Vietnam Pro",
                          fontWeight: "700",
                        }}
                      >
                        Anonymous
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        Arizona State University Internship at Lazard
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} spacing={2} sm={6} md={4}>
                <Card
                  sx={{ maxWidth: 345, padding: 2, borderRadius: 4, height: 1 }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Avatar />
                      </Grid>

                      <Grid item alignItems="end" justifyContent="flex-end">
                        <Rating
                          value={5}
                          readOnly
                          sx={{ color: COLORS.starColor }}
                        />
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        "When I was a sophomore, I made sure to explore various
                        interview prep resources, and I didn’t hold back on
                        spending. I tried a few pricey courses with long video
                        tutorials and hundreds of pages to read, hoping they
                        would make all the difference, but it turns out that
                        most of the materials and guides are readily available
                        from my student-run clubs anyways. When I found
                        FinancePrep, I didn’t think much since it was a lot
                        cheaper.<br></br>
                        Coming from a target school and being involved in the IB
                        and banking clubs, I had access to countless resources
                        and endless practice questions, but FinancePrep still
                        managed to blow me away with its up-to-date, real
                        interview questions at firms and groups that even my
                        club at Stern didn’t cover - I was asked the exact same
                        questions in a couple of first rounds!"
                      </Typography>
                    </Box>
                    <Grid item xs mt={2}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Be Vietnam Pro",
                          fontWeight: "700",
                        }}
                      >
                        Anonymous
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        NYU stern Analyst at JP Morgan M&A
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} spacing={2} sm={6} md={4}>
                {" "}
                <Card
                  sx={{
                    height: 1,
                    maxWidth: 345,
                    padding: 2,
                    borderRadius: 4,
                    flexGrow: 1,
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Avatar />
                      </Grid>

                      <Grid item alignItems="end" justifyContent="flex-end">
                        <Rating
                          value={5}
                          readOnly
                          sx={{ color: COLORS.starColor }}
                        />
                      </Grid>
                    </Box>
                    <Box mt={3.5}>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        "As an analyst currently applying for private equity
                        roles. The grind never stops in this industry, even
                        after securing IB. I’m always preparing for the next
                        round of interviews smh<br></br>I initially found
                        FinancePrep during my internship search days. It didn’t
                        blow me away, but it has its benefits. I found myself
                        using it more consistently than I expected. The
                        mobile-friendly design was convenient, allowing me to
                        practice on the go, which fit my busy schedule well.
                        There’s no way I’d be reading a 400-page PDF on the
                        metro in between coffee chats, so I’ll always fire up
                        FinancePrep to sharpen up the technicals
                      </Typography>
                    </Box>
                    <Grid item xs mt={4.5}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Be Vietnam Pro",
                          fontWeight: "700",
                        }}
                      >
                        Anonymous
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        UPenn Analyst
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} spacing={2} sm={6} md={4}>
                {" "}
                <Card
                  sx={{
                    height: 1,
                    maxWidth: 345,
                    padding: 2,
                    borderRadius: 4,
                    flexGrow: 1,
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Avatar />
                      </Grid>

                      <Grid item alignItems="end" justifyContent="flex-end">
                        <Rating
                          value={5}
                          readOnly
                          sx={{ color: COLORS.starColor }}
                        />
                      </Grid>
                    </Box>
                    <Box mt={3.5}>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        Don’t take our word for it FinancePrep.io is an
                        exceptional platform for anyone aiming to excel in
                        finance. The comprehensive and user-friendly interface
                        simplifies complex financial concepts, making learning
                        both straightforward and engaging. The personalized
                        study plans and progress tracking features kept me
                        focused and motivated throughout my preparation.
                        Expert-led webinars and a supportive community forum
                        provided invaluable insights and guidance. Thanks to
                        FinancePrep.io , I passed my finance exams with
                        confidence and feel well-prepared for real-world
                        financial challenges.
                      </Typography>
                    </Box>
                    <Grid item xs mt={4.5}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Be Vietnam Pro",
                          fontWeight: "700",
                        }}
                      >
                        Anonymous
                      </Typography>
                      {/* <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        UPenn Analyst
                      </Typography> */}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} spacing={2} sm={6} md={4}>
                {" "}
                <Card
                  sx={{
                    height: 1,
                    maxWidth: 345,
                    padding: 2,
                    borderRadius: 4,
                    flexGrow: 1,
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Avatar />
                      </Grid>

                      <Grid item alignItems="end" justifyContent="flex-end">
                        <Rating
                          value={5}
                          readOnly
                          sx={{ color: COLORS.starColor }}
                        />
                      </Grid>
                    </Box>
                    <Box mt={3.5}>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        I had a fantastic experience with FinancePrep.io , which
                        made mastering finance concepts remarkably easy. The
                        platform's well-structured resources, including practice
                        exams and study guides, were incredibly helpful. I
                        particularly appreciated the personalized study plans
                        that kept me organized and focused. The expert webinars
                        provided deep insights that enhanced my understanding.
                        Thanks to FinancePrep.io , I passed my finance exams and
                        am well-prepared for my career in finance.
                      </Typography>
                    </Box>
                    <Grid item xs mt={4.5}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Be Vietnam Pro",
                          fontWeight: "700",
                        }}
                      >
                        Anonymous
                      </Typography>
                      {/* <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        UPenn Analyst
                      </Typography> */}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} spacing={2} sm={6} md={4}>
                {" "}
                <Card
                  sx={{
                    height: 1,
                    maxWidth: 345,
                    padding: 2,
                    borderRadius: 4,
                    flexGrow: 1,
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Avatar />
                      </Grid>

                      <Grid item alignItems="end" justifyContent="flex-end">
                        <Rating
                          value={5}
                          readOnly
                          sx={{ color: COLORS.starColor }}
                        />
                      </Grid>
                    </Box>
                    <Box mt={3.5}>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        FinancePrep.io transformed my finance exam preparation
                        with its intuitive and comprehensive platform. The
                        detailed study guides and interactive practice exams
                        made even the most challenging topics accessible and
                        understandable. The personalized study plans and
                        progress tracking features were instrumental in keeping
                        me on track. The expert-led webinars and community
                        support added an extra layer of invaluable insights.
                        Passing my finance exams with high scores, I now feel
                        confident to face real-world financial challenges.
                      </Typography>
                    </Box>
                    <Grid item xs mt={4.5}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Be Vietnam Pro",
                          fontWeight: "700",
                        }}
                      >
                        Anonymous
                      </Typography>
                      {/* <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        UPenn Analyst
                      </Typography> */}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} spacing={2} sm={6} md={4}>
                <Card
                  sx={{
                    maxWidth: 345,
                    padding: 2,
                    borderRadius: 4,
                    height: "100%",
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Avatar />
                      </Grid>

                      <Grid item alignItems="end" justifyContent="flex-end">
                        <Rating
                          value={5}
                          readOnly
                          sx={{ color: COLORS.starColor }}
                        />
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        FinancePrep.io is an outstanding resource for anyone
                        pursuing finance studies. The platform's user-friendly
                        interface and comprehensive materials simplified complex
                        topics for me. Personalized study plans and effective
                        progress tracking ensured I stayed on course. Expert-led
                        webinars and community forums offered crucial support
                        and insights. With the help of FinancePrep.io , I
                        successfully passed my finance exams and feel ready to
                        tackle any financial challenge.
                      </Typography>
                    </Box>
                    <Grid item xs mt={2}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Be Vietnam Pro",
                          fontWeight: "700",
                        }}
                      >
                        Anonymous
                      </Typography>
                      {/* <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ fontFamily: "Be Vietnam Pro" }}
                        >
                          Junior Accountant
                        </Typography> */}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} spacing={2} sm={6} md={4}>
                <Card sx={{ maxWidth: 345, padding: 2, borderRadius: 4 }}>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Avatar />
                      </Grid>

                      <Grid item alignItems="end" justifyContent="flex-end">
                        <Rating
                          value={5}
                          readOnly
                          sx={{ color: COLORS.starColor }}
                        />
                      </Grid>
                    </Box>
                    <Box mt={2}>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Be Vietnam Pro" }}
                      >
                        Using FinancePrep.io was a game-changer for my finance
                        exam preparation. The platform's extensive study
                        materials and interactive features made learning
                        engaging and effective. Personalized study plans and
                        progress tracking kept me motivated and on schedule. The
                        expert webinars were incredibly insightful, providing a
                        deeper understanding of key concepts. Thanks to
                        FinancePrep.io , I passed my exams with confidence and
                        am now ready for my professional journey in finance.
                      </Typography>
                    </Box>
                    <Grid item xs mt={2}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Be Vietnam Pro",
                          fontWeight: "700",
                        }}
                      >
                        Anonymous
                      </Typography>
                      {/* <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ fontFamily: "Be Vietnam Pro" }}
                        >
                          Sales Consultant
                        </Typography> */}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default IHomiesTestimonials;
