import React, { useEffect, useRef, useState } from "react";

const Counter = ({ endValue, duration }: any) => {
  const [count, setCount] = useState(0);
  const ref: any = useRef();

  useEffect(() => {
    let observer: any;
    const handleObserver = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          let start: any = null;
          const step = (timestamp: any) => {
            if (!start) start = timestamp;
            const progress = Math.min((timestamp - start) / duration, 1);
            setCount(Math.floor(progress * endValue));
            if (progress < 1) {
              window.requestAnimationFrame(step);
            }
          };
          window.requestAnimationFrame(step);
          observer.unobserve(ref.current);
        }
      });
    };

    observer = new IntersectionObserver(handleObserver, {
      threshold: 0.1,
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer && observer.disconnect();
  }, [endValue, duration]);

  return <span ref={ref}>{count}</span>;
};

export default Counter;
