import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";

import { Icon } from "@iconify/react";
import { PATH } from "../../constant";
import { useNavigate } from "react-router-dom";

import { useBoolean } from "../../hooks/use-boolean";
import toast from "react-hot-toast";

interface Column {
  id: "name" | "email" | "action";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "email", label: "E-mail", minWidth: 100 },
];

interface Data {
  name: string;
  isActive: boolean;
}

function UserList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [user, setUser] = React.useState([]);
  const rows: any[] = user;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const confirm = useBoolean();

  const [deleteId, setDeleteId] = useState<string | null>(null); // State to hold the ID of the firm to be deleted

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const snapshot = await firebase.firestore().collection("users").get();
      const activeUsers: any = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUser(activeUsers);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Users:", error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchUsers();
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Typography variant="h4">Users</Typography>
      </Box>

      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.email}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default UserList;
