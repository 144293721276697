import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from "@mui/material";
import { COLORS } from "../../common/color";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import { Icon } from "@iconify/react";
import React from "react";
type Props = {
  row?: any;
  fetchCategory: any;
};
function CategoryDialog({ row, fetchCategory }: Props) {
  const [loading, setLoading] = useState(false);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [selectedFirm, setSelectedFirm] = useState<any>(true);
  const [firm, setFirm] = React.useState([]);
  const [selectedcategory, setSelectedCategory] = useState<any>(true);
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setSelectedCategory(event.target.value);
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const fetchFirms = async () => {
    try {
      setLoading(true);
      const snapshot = await firebase
        .firestore()
        .collection("firms")
        .where("isActive", "==", true)
        .get();
      const activeFirms: any = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFirm(activeFirms);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching firms:", error);
      setLoading(false);
    }
  };

  const fetchCategorys = async () => {
    try {
      const firmsRef = firebase.firestore().collection("category").doc(row.id);
      const doc: any = await firmsRef.get();
      if (doc.exists) {
        const categoryData = doc.data();
        categoryData.id = row.id;
        setValue("name", categoryData.name);
        setSelectedCategory(categoryData.isActive);
        setSelectedFirm(categoryData.firmId);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching:", error);
    }
  };
  useEffect(() => {
    fetchFirms();
    if (row) {
      fetchCategorys();
    }
  }, [row, setValue]);

  const handleClickOpenCategoryDilog = () => {
    fetchFirms();
    setOpenCategoryDialog(true);
  };

  const onSubmit = async (data: any) => {
    try {
      if (row) {
        setLoading(true);
        const categoryRef = firebase
          .firestore()
          .collection("category")
          .doc(row.id);
        await categoryRef.update({
          name: data.name,
          isActive: selectedcategory === "true" ? true : false,
          firmId: selectedFirm,
        });
        setLoading(false);
        toast.success("Category Upadated Sucessfully", {
          duration: 4000,
          position: "bottom-right",

          // Styling
          style: {},
          className: "",

          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        });
        setOpenCategoryDialog(false);
        reset();
        fetchCategory();
      } else {
        setLoading(true);
        const categoryData = {
          name: data.name,
          isActive: selectedcategory === "true" ? true : false,
          firmId: selectedFirm,
        };

        await firebase.firestore().collection("category").add(categoryData);

        setLoading(false);
        toast.success("Category Added Sucessfully", {
          duration: 4000,
          position: "bottom-right",

          // Styling
          style: {},
          className: "",

          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        });
        setOpenCategoryDialog(false);
        reset();
        fetchCategory();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleCloseCategoryDialog = () => {
    setOpenCategoryDialog(false);
    reset();
    fetchCategory();
  };

  const handleFirmChange = (event: SelectChangeEvent) => {
    setSelectedFirm(event.target.value);
  };
  return (
    <>
      {!row ? (
        <Button
          onClick={handleClickOpenCategoryDilog}
          sx={{
            // backgroundColor: COLORS.black,
            width: "fit-content",
            color: "#fff",
            backgroundColor: "#573CFF",
            p: "8px 12px",
            textTransform: "none",
            fontWeight: 500,
            "&:hover": {
              backgroundColor: "#3D66FB",
            },
          }}
        >
          Add Category
        </Button>
      ) : (
        <Tooltip title="Quick Edit" placement="top" arrow>
          <IconButton onClick={handleClickOpenCategoryDilog}>
            <Icon icon="solar:pen-bold" />
          </IconButton>
        </Tooltip>
      )}

      <Dialog
        open={openCategoryDialog}
        onClose={handleCloseCategoryDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {row ? "Edit Category" : "Create Category"}{" "}
          <Button
            onClick={handleCloseCategoryDialog}
            sx={{
              backgroundColor: COLORS.white,
              width: "fit-content",
              color: COLORS.black,
              "&:hover": {
                backgroundColor: `${COLORS.black} !important`,
                color: `${COLORS.white} !important`,
              },
            }}
          >
            <CloseIcon />
          </Button>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent sx={{ width: "100%" }}>
            <TextField
              sx={{ mt: 2 }}
              label="Name"
              {...register("name", { required: true })}
              placeholder="Enter Firm Name"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            {errors.name && errors.name.type === "required" && (
              <p className="errorMsg" style={{ color: "red" }}>
                Category Name is required
              </p>
            )}

            <FormControl sx={{ marginTop: "20px" }} fullWidth>
              <InputLabel id="demo-simple-select-autowidth-label">
                Select Firm
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={selectedFirm}
                label="Select Category"
                onChange={handleFirmChange}
              >
                {firm.map((firm: any) => (
                  <MenuItem key={firm.id} value={firm.id}>
                    {firm.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ marginTop: "20px" }} fullWidth>
              <InputLabel id="demo-simple-select-autowidth-label">
                isActive
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={selectedcategory}
                label="Select isActive"
                onChange={handleCategoryChange}
              >
                {["true", "false"].map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions sx={{ marginRight: 2 }}>
            <Button
              type="submit"
              sx={{
                backgroundColor: COLORS.black,
                color: COLORS.white,
                "&:hover": {
                  backgroundColor: `${COLORS.black} !important`,
                  color: `${COLORS.white} !important`,
                },
                textTransform: "none",
              }}
            >
              {loading ? (
                <CircularProgress size={22} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
export default CategoryDialog;
