import { Box } from "@mui/material";
import { getAuth } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { PATH } from "./constant";

const PublicRoute = ({ children }: { children: React.ReactElement }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Added loading state
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged(async (user) => {
      try {
        setIsAuthenticated(!!user);
        setIsLoading(false);
      } catch (error) {}
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return <></>;
  }

  if (isAuthenticated) {
    return (
      <Navigate
        to={PATH.question}
        replace
        state={{ from: location.pathname, search: location.search }}
      />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        {children}
      </Box>
    </Box>
  );
};

export default PublicRoute;
