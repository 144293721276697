import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Home from "./pages/home";
import TermsOfService from "./pages/termsofservice";
import Question from "./pages/question";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import { Container } from "@mui/material";
import Header from "./pages/header";
import Footer from "./pages/footer";
import Pricing from "./component/pricing";
import { PATH } from "./constant";
import { Toaster } from "react-hot-toast";
import Subscription from "./pages/pricing";
import Admin from "./pages/admin";
import IHomies from "./pages/iHomies";
import IHomiesHeader from "./component/iHomies-header";
import { AuthProvider } from "./context/AuthContext";
import IHomiesFooter from "./component/iHomies-footer";
import IHomiesPricing from "./component/iHomies-pricing";
import IHomiesTestimonials from "./component/iHomies-testimonials";

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyAcx-vHFs-9wLAqE7JuWwJBoJ-qCrvYrOM",
    authDomain: "techmate-afc28.firebaseapp.com",
    databaseURL: "https://techmate-afc28-default-rtdb.firebaseio.com",
    projectId: "techmate-afc28",
    storageBucket: "techmate-afc28.appspot.com",
    messagingSenderId: "915573537775",
    appId: "1:915573537775:web:56192a34469d3f8922541a",
    measurementId: "G-C79HCT8543",
  };
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  return (
    <>
      <AuthProvider>
        <Toaster />
        <Router>
          <IHomiesHeader />
          <Routes>
            <Route
              path={PATH.home}
              element={
                <PublicRoute>
                  <IHomies />
                </PublicRoute>
              }
            />
            <Route
              path={PATH.termsofservice}
              element={
                <PublicRoute>
                  <TermsOfService />
                </PublicRoute>
              }
            />
            <Route
              path={PATH.question}
              element={
                <PrivateRoute>
                  <Question />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH.pricing}
              element={
                <PrivateRoute>
                  <IHomiesPricing />
                </PrivateRoute>
              }
            />
            <Route
              path={PATH.testimonials}
              element={
                <PrivateRoute>
                  <IHomiesTestimonials />
                </PrivateRoute>
              }
            />
            {/* <Route path={PATH.pricing} element={<Subscription />} /> */}
            <Route
              path={PATH.admin}
              element={
                <PrivateRoute allowRoles={["admin"]}>
                  <Admin />
                </PrivateRoute>
              }
            />
          </Routes>
          <IHomiesFooter />
        </Router>
      </AuthProvider>
    </>
  );
}

export default App;
