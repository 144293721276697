import {
  Typography,
  Box,
  Button,
  Container,
  Stack,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import img from "../../assets/iHomies-img/real_time_data_icon_resized.png";
import img1 from "../../assets/iHomies-img/Professional_Business_Meeting_500x350.png";
import img2 from "../../assets/iHomies-img/Slider.svg";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import Slider from "react-slick";

import { useAuth } from "../../context/AuthContext";
import ScrollAnimation from "../ScrollView";

function IHomiesFeatures() {
  const [sliderRef, setSliderRef] = useState<any>(null);
  const logoSlides = [
    {
      question: "Walk me through an investment banking deal.",
    },
    { question: "What are some ways to value a company?" },
    {
      question:
        "Tell me about your greatest accomplishment and how this showed personal initiative?",
    },
    { question: "Pitch me a stock. Why is it interesting?" },
    { question: "What are the levers to drive value in an LBO?" },
  ];
  const { onGoogleClick } = useAuth();
  const sliderSettings = {
    arrows: false,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,

    infinite: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Container maxWidth="lg">
      {/* User-Friendly Interface  */}
      <ScrollAnimation>
        <Box
          sx={{
            display: "flex",
            gap: 6,
            my: 12,
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              order: { xs: "2", md: "1" },
            }}
          >
            <Box
              sx={{
                width: "140px",
                borderRadius: 7,
                backgroundColor: "#F3F7FB",
                color: " linear-gradient(271.22deg, #615EE8 0%, #6D8DFF 100%)",
              }}
            >
              <Typography
                fontWeight={600}
                sx={{
                  textAlign: "center",
                  p: 1,
                  background:
                    "linear-gradient(271.24deg, #A15EE8 0%, #6D8DFF 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontFamily: "Be Vietnam Pro",
                }}
              >
                FEATURE
              </Typography>
            </Box>
            <Typography
              variant="h3"
              sx={{
                color: "#02015A",
                fontWeight: 500,
                fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem" },
                fontFamily: "Be Vietnam Pro",
              }}
            >
              User-Friendly Interface
            </Typography>
            <Typography
              sx={{
                color: "#68708C",
                fontSize: { xs: "1rem", md: "1rem" },
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Our Finance Question Bank offers a user-friendly interface with
              intuitive navigation and easy access, helping users prepare
              efficiently for interviews.
            </Typography>
            <Button
              onClick={onGoogleClick}
              sx={{
                width: "fit-content",
                border: "1px solid #3D66FB",
                color: "#3D66FB",
                textTransform: "none",
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Get Started
              <ArrowOutwardIcon />
            </Button>
          </Box>
          <Box sx={{ width: "500px", order: { xs: "1", md: "2" } }}>
            <Slider ref={setSliderRef} {...sliderSettings}>
              {logoSlides.map((ele, index) => (
                <Box>
                  <Box
                    key={index}
                    // component="img"
                    // src={ele}
                    sx={{
                      height: "40vh",
                      border: "1px solid #573CFF",
                      margin: "auto",

                      width: "450px !important",
                      marginBottom: "20px",
                      borderRadius: "10px",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0 5px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight={600}
                      sx={{ fontFamily: "Be Vietnam Pro" }}
                    >
                      {ele.question}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Slider>
            <Stack direction={"row"} justifyContent={"center"} gap={1}>
              <IconButton
                onClick={sliderRef?.slickPrev}
                size="small"
                sx={{ bgcolor: "#573CFF", ":hover": { bgcolor: "#573CFF" } }}
              >
                <KeyboardDoubleArrowLeftIcon sx={{ color: "white" }} />
              </IconButton>
              <IconButton
                onClick={sliderRef?.slickNext}
                size="small"
                sx={{ bgcolor: "#573CFF", ":hover": { bgcolor: "#573CFF" } }}
              >
                <KeyboardDoubleArrowRightIcon sx={{ color: "white" }} />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      </ScrollAnimation>
      {/* Real-Time Data */}
      <ScrollAnimation>
        <Box
          sx={{
            display: "flex",
            gap: 6,
            my: 12,
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={img}
            sx={{ order: "1", minWidth: "500px", borderRadius: 2.5 }}
          ></Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              order: "2",
            }}
          >
            <Box
              sx={{
                width: "140px",
                borderRadius: 7,
                backgroundColor: "#F3F7FB",
                color: " linear-gradient(271.22deg, #615EE8 0%, #6D8DFF 100%)",
              }}
            >
              <Typography
                fontWeight={600}
                sx={{
                  textAlign: "center",
                  p: 1,
                  background:
                    "linear-gradient(271.24deg, #A15EE8 0%, #6D8DFF 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontFamily: "Be Vietnam Pro",
                }}
              >
                FEATURE
              </Typography>
            </Box>
            <Typography
              variant="h3"
              sx={{
                color: "#02015A",
                fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem" },
                fontWeight: 500,
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Real-Time Data Collection
            </Typography>
            <Typography
              sx={{
                color: "#68708C",
                fontSize: { xs: "1rem", md: "1rem" },
                fontFamily: "Be Vietnam Pro",
              }}
            >
              We are constantly upgrading our database of questions to ensure it
              remains comprehensive and up-to-date with the latest information.
            </Typography>
            <Button
              onClick={onGoogleClick}
              sx={{
                width: "fit-content",
                border: "1px solid #3D66FB",
                color: "#3D66FB",
                textTransform: "none",
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Get Started
              <ArrowOutwardIcon />
            </Button>
          </Box>
        </Box>
      </ScrollAnimation>
      <ScrollAnimation>
        {/* Industry Expert Involvement */}
        <Box
          sx={{
            display: "flex",
            gap: 6,
            my: 12,
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              order: { xs: "2", md: "1" },
            }}
          >
            <Box
              sx={{
                width: "140px",
                borderRadius: 7,
                backgroundColor: "#F3F7FB",
                color: " linear-gradient(271.22deg, #615EE8 0%, #6D8DFF 100%)",
              }}
            >
              <Typography
                fontWeight={600}
                sx={{
                  textAlign: "center",
                  p: 1,
                  background:
                    "linear-gradient(271.24deg, #A15EE8 0%, #6D8DFF 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontFamily: "Be Vietnam Pro",
                }}
              >
                FEATURE
              </Typography>
            </Box>
            <Typography
              variant="h3"
              sx={{
                color: "#02015A",
                fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem" },
                fontWeight: 500,
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Broad Bank & Industry Group Coverage
            </Typography>
            <Typography
              sx={{
                color: "#68708C",
                fontSize: { xs: "1rem", md: "1rem" },
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Our Finance Question Bank consists of 150+ questions from 10 top
              banks like Evercore and Goldman Sachs.These are expert-verified
              questions for effective interview preparation.
            </Typography>
            <Button
              onClick={onGoogleClick}
              sx={{
                width: "fit-content",
                border: "1px solid #3D66FB",
                color: "#3D66FB",
                textTransform: "none",
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Get Started
              <ArrowOutwardIcon />
            </Button>
          </Box>
          <Box
            component="img"
            src={img1}
            sx={{
              borderRadius: 2.5,
              objectFit: "cover",
              order: { xs: "1", md: "2" },
              minWidth: "500px",
            }}
          ></Box>
        </Box>
      </ScrollAnimation>
    </Container>
  );
}

export default IHomiesFeatures;
