import axios from "axios";
import { action, thunk } from "easy-peasy";
import { getAuth } from "firebase/auth";

const Payment = {
  payment: {},
  setPayment: action((state, payload) => {
    state.payment = payload;
  }),
  getPayment: thunk(async (actions, payload) => {
    const idToken = await getAuth()?.currentUser?.getIdToken(true);

    const res = await axios.get(
      "https://asia-south1-techmate-753f5.cloudfunctions.net/finetech/current-subscription",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken,
        },
      }
    );
    actions.setPayment(res.data?.data);
    return res;
  }),
};
export default Payment;
