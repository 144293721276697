import {
  Badge,
  Box,
  Button,
  Card,
  Chip,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { useEffect, useState } from "react";
import { COLORS } from "../../common/color";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useStoreState } from "easy-peasy";
import { ReduxStoreI } from "../../store/redux-store";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../constant";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import UpgradeCard from "./UpgradeCard";

function Question() {
  const [loadingFirms, setLoadingFirms] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [flipped, setFlipped] = useState(false); // State to track card flip

  const [categories, setCategories] = useState([]); // State to store active categories
  const [firm, setFirm] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(""); // State to store selected category
  const [selectedFirm, setSelectedFirm] = useState("");
  const [questions, setQuestions] = useState<any>([]); // State to store questions for the selected category
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // State to store the index of the current question
  const [selectedShows, setSelectedShows] = useState(false);
  const { payment } = useStoreState((state: ReduxStoreI) => state.Payment);

  const navigate = useNavigate();

  useEffect(() => {
    if (payment?.subscription?.status === "active") {
      fetchFirms();
    } else {
      setSelectedShows(true);
      fetchFirms();
      // navigate(PATH.pricing);
    }
  }, [payment]);

  const goToPricing = () => {
    navigate(PATH.pricing);
  };

  useEffect(() => {
    if (selectedFirm) {
      fetchCategories(selectedFirm);
    }
  }, [selectedFirm]);

  useEffect(() => {
    if (selectedCategory) {
      fetchQuestions(selectedCategory);
    }
  }, [selectedCategory]);

  const fetchCategories = async (firmId: string) => {
    try {
      setLoadingCategories(true);
      const snapshot = await firebase
        .firestore()
        .collection("category")
        .where("firmId", "==", firmId)
        .where("isActive", "==", true)
        .get();
      const activeCategories: any = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(activeCategories);
      setLoadingCategories(false);
      // Select the first category by default
      if (activeCategories.length > 0) {
        setSelectedCategory(activeCategories[0].id);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoadingCategories(false);
    }
  };

  const fetchFirms = async () => {
    try {
      setLoadingFirms(true);
      const snapshot = await firebase
        .firestore()
        .collection("firms")
        .where("isActive", "==", true)
        .get();
      const activeFirms: any = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFirm(activeFirms);
      setLoadingFirms(false);
      // Select the first category by default
      if (activeFirms.length > 0) {
        setSelectedFirm(activeFirms[0].id);
      }
    } catch (error) {
      console.error("Error fetching firms:", error);
      setLoadingFirms(false);
    }
  };

  const fetchQuestions = async (categoryID: string) => {
    try {
      setLoadingQuestions(true);

      let query = firebase
        .firestore()
        .collection("questions")
        .where("categoryId", "==", categoryID);

      if (selectedShows) {
        query = query.where("isShow", "==", true);
      }

      const snapshot = await query.get();

      const categoryQuestions: any = snapshot.docs.map((doc) => doc.data());
      setQuestions(categoryQuestions);
      setLoadingQuestions(false);
    } catch (error) {
      setLoadingQuestions(false);
      console.error("Error fetching questions:", error);
    }
  };

  const handleNextClick = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handlePrevClick = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setFlipped(false);
    setSelectedCategory(event.target.value);
    setCurrentQuestionIndex(0);
  };

  const handleFirmChange = (event: SelectChangeEvent) => {
    setFlipped(false);
    setSelectedFirm(event.target.value);
    setCurrentQuestionIndex(0);
  };

  useEffect(() => {
    if (!questions.length) return;
    const handleKeyDown = (event: any) => {
      if (event.key === "Enter") {
        // setFlipped((prev) => !prev);
      } else if (event.key === "ArrowLeft") {
        handlePrevClick();
        setFlipped(false);
      } else if (event.key === "ArrowRight") {
        handleNextClick();
        setFlipped(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [questions, currentQuestionIndex]);

  const isLoading = loadingFirms || loadingCategories || loadingQuestions;

  return (
    <div tabIndex={0}>
      <Container maxWidth={"lg"}>
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            },
            justifyContent: {
              xs: "center",
              sm: "center",
              md: "flex-start",
              lg: "flex-start",
            },
            alignItems: {
              xs: "center",
              sm: "center",
              md: "flex-start",
              lg: "flex-start",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "column",
              },
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "8px",
            }}
          >
            <FormControl
              sx={{
                m: 1,
                minWidth: 200,
                backgroundColor: `${COLORS.headerBackGroundColor}`,
              }}
            >
              <InputLabel id="demo-simple-select-autowidth-label">
                Select Firm
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={selectedFirm}
                label="Select Firm"
                onChange={handleFirmChange}
              >
                {firm.map((firm: any) => (
                  <MenuItem key={firm.id} value={firm.id}>
                    {firm.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                m: 1,
                minWidth: 200,
                backgroundColor: `${COLORS.headerBackGroundColor}`,
              }}
            >
              <InputLabel id="demo-simple-select-autowidth-label">
                Select Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={selectedCategory}
                label="Select Category"
                onChange={handleChange}
              >
                {categories.map((category: any) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {payment?.subscription?.status !== "active" && <UpgradeCard />}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "column",
              },
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              className={"flip-card"}
              onClick={() => setFlipped(!flipped)}
              style={{
                transform: flipped ? "rotateX(180deg)" : "rotateX(0deg)",
                transition: "0.6s",
                marginTop: "10px",
              }}
            >
              {isLoading ? (
                <Box
                  sx={{
                    marginTop: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <CircularProgress color="inherit" />
                </Box>
              ) : (
                <>
                  <div
                    className="flip-card-inner"
                    // onClick={() => setFlipped(!flipped)}
                    style={
                      {
                          transform: flipped ? "rotateX(360deg)" : "rotateX(0deg)",
                          transition: "0.8s",
                      }
                    }
                  >
                    {questions.length > 0 ? (
                      !flipped ? (
                        <div
                          className="flip-card-front"
                          style={{ padding: "20px" }}
                        >
                          <h2>{questions[currentQuestionIndex].question}</h2>
                        </div>
                      ) : (
                        <div
                          className="flip-card-back"
                          style={{
                            transform: "scale(-1, 1)",
                            rotate: "180deg",
                          }}
                        >
                          <h2>{questions[currentQuestionIndex].answer}</h2>
                        </div>
                      )
                    ) : (
                      <div
                        className="flip-card-back"
                        style={{ rotate: "180deg" }}
                      >
                        <h2>No Questions for this Category</h2>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                flexDirection: {
                  lg: "row",
                  md: "row",
                  sm: "row",
                  xs: "row",
                },
                justifyContent: {
                  lg: "space-between",
                  md: "space-between",
                  sm: "space-between",
                  xs: "space-between",
                },
                alignItems: "center",
                width: {
                  lg: "20%",
                  md: "20%",
                  sm: "30%",
                  xs: "50%",
                },
                marginBottom: "20px",
                height: "85px",
              }}
            >
              <IconButton
                // onClick={sliderRef?.slickPrev}
                size="medium"
                sx={{
                  backgroundColor: `${COLORS.questionButton}`,
                  color: `${COLORS.white} `,
                  "&:hover": {
                    backgroundColor: `${COLORS.questionButton} `,
                    color: `${COLORS.white} `,
                  },
                  marginTop: "20px",
                  textTransform: "none",

                  ":disabled": {
                    backgroundColor: `${COLORS.lightGray} `,
                    color: `${COLORS.white} `,
                  },
                }}
                disabled={currentQuestionIndex === 0 || questions.length === 0}
                onClick={() => {
                  handlePrevClick();
                  setFlipped(false);
                }}
              >
                <KeyboardDoubleArrowLeftIcon sx={{ color: "white" }} />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginTop: "20px",
                }}
              >
                <Box sx={{ color: COLORS.questionButton, marginRight: "5px" }}>
                  {currentQuestionIndex + 1}
                </Box>
                / {questions.length}
              </Box>

              <IconButton
                size="medium"
                sx={{
                  backgroundColor: `${COLORS.questionButton}`,
                  color: `${COLORS.white} `,
                  "&:hover": {
                    backgroundColor: `${COLORS.questionButton} `,
                    color: `${COLORS.white} `,
                  },
                  marginTop: "20px",
                  textTransform: "none",
                  ":disabled": {
                    backgroundColor: `${COLORS.lightGray} `,
                    color: `${COLORS.white} `,
                  },
                }}
                disabled={
                  currentQuestionIndex === questions.length - 1 ||
                  questions.length === 0
                }
                onClick={() => {
                  handleNextClick();
                  setFlipped(false);
                }}
              >
                <KeyboardDoubleArrowRightIcon sx={{ color: "white" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default Question;
