import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getAuth } from "firebase/auth";
import { useStoreActions } from "easy-peasy";
import { ReduxStoreI } from "./store/redux-store";
import { PATH } from "./constant";
import { CircularProgress } from "@mui/material";

const PrivateRoute = ({
  allowRoles,
  children,
}: {
  allowRoles?: any;
  children: React.ReactElement;
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [role, setRole] = useState<any>(null);
  const location = useLocation();
  const { getPayment } = useStoreActions(
    (actions: ReduxStoreI) => actions.Payment
  );

  useEffect(() => {
    const fetchAuthAndRole = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        setIsAuthenticated(!!user);

        if (user) {
          await getPayment();

          const firmsRef = firebase
            .firestore()
            .collection("users")
            .doc(user.uid);
          const doc = await firmsRef.get();

          if (doc.exists) {
            const userData = doc.data();
            let userRole = userData?.role;

            if (!userRole) {
              userRole = ["user"];
            } else if (!Array.isArray(userRole)) {
              userRole = [userRole];
            }

            setRole(userRole);
          } else {
            throw new Error("User document does not exist");
          }
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching auth state and role:", error);
      }
    };

    const unsubscribe = getAuth().onAuthStateChanged(() => {
      fetchAuthAndRole();
    });

    return () => unsubscribe();
  }, [getPayment]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress size={22} color="inherit" />
      </div>
    ); // You can replace this with a proper loading indicator
  }

  if (!isAuthenticated) {
    return (
      <Navigate
        to="/"
        replace
        state={{ from: location.pathname, search: location.search }}
      />
    );
  }

  if (
    allowRoles &&
    role &&
    !role.some((allow: any) => allowRoles.includes(allow))
  ) {
    return <Navigate to={PATH.home} replace />;
  }

  return children;
};

export default PrivateRoute;
