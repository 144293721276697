import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from "@mui/material";
import { COLORS } from "../../common/color";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import { Icon } from "@iconify/react";
type Props = {
  row?: any;
  fetchFirms: any;
};
function FirmDialog({ row, fetchFirms }: Props) {
  const [loading, setLoading] = useState(false);
  const [openFirmDialog, setOpenFirmDialog] = useState(false);
  const [selectedFirm, setSelectedFirm] = useState<any>(true);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const fetchFirm = async () => {
    try {
      const firmsRef = firebase.firestore().collection("firms").doc(row.id);
      const doc: any = await firmsRef.get();
      if (doc.exists) {
        const firmsData = doc.data();
        firmsData.id = row.id;
        setValue("name", firmsData.name);
        setSelectedFirm(firmsData.isActive);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching:", error);
    }
  };
  useEffect(() => {
    if (row) {
      fetchFirm();
    }
  }, [row]);

  const onSubmit = async (data: any) => {
    try {
      if (row) {
        setLoading(true);
        const firmsRef = firebase.firestore().collection("firms").doc(row.id);
        await firmsRef.update({
          name: data.name,
          isActive: selectedFirm === "true" ? true : false,
        });
        setLoading(false);
        toast.success("Firm Upadated Sucessfully", {
          duration: 4000,
          position: "bottom-right",

          // Styling
          style: {},
          className: "",

          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        });
        setOpenFirmDialog(false);
        reset();
        fetchFirms();
      } else {
        setLoading(true);
        const firmData = {
          name: data.name,
          isActive: selectedFirm === "true" ? true : false,
        };
        await firebase.firestore().collection("firms").add(firmData);

        setLoading(false);
        toast.success("Firm Added Sucessfully", {
          duration: 4000,
          position: "bottom-right",

          // Styling
          style: {},
          className: "",

          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
        });
        setOpenFirmDialog(false);
        reset();
        fetchFirms();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleClickOpenFirmDilog = () => {
    setOpenFirmDialog(true);
  };

  const handleCloseFirmDialog = () => {
    setOpenFirmDialog(false);
    reset();
    fetchFirms();
  };

  const handleFirmChange = (event: SelectChangeEvent) => {
    setSelectedFirm(event.target.value);
  };
  return (
    <>
      {!row ? (
        <Button
          onClick={handleClickOpenFirmDilog}
          sx={{
            // backgroundColor: COLORS.black,
            width: "fit-content",
            color: "#fff",
            backgroundColor: "#573CFF",
            p: "8px 12px",
            textTransform: "none",
            fontWeight: 500,
            "&:hover": {
              backgroundColor: "#3D66FB",
            },
          }}
        >
          Add Firm
        </Button>
      ) : (
        <Tooltip title="Quick Edit" placement="top" arrow>
          <IconButton onClick={handleClickOpenFirmDilog}>
            <Icon icon="solar:pen-bold" />
          </IconButton>
        </Tooltip>
      )}

      <Dialog
        open={openFirmDialog}
        onClose={handleCloseFirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {row ? "Edit Firm" : "Create Firm"}{" "}
          <Button
            onClick={handleCloseFirmDialog}
            sx={{
              backgroundColor: COLORS.white,
              width: "fit-content",
              color: COLORS.black,
              "&:hover": {
                backgroundColor: `${COLORS.black} !important`,
                color: `${COLORS.white} !important`,
              },
            }}
          >
            <CloseIcon />
          </Button>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent sx={{ width: "100%" }}>
            <TextField
              sx={{ mt: 2 }}
              label="Name"
              {...register("name", { required: true })}
              placeholder="Enter Firm Name"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            {errors.userName && errors.userName.type === "required" && (
              <p className="errorMsg" style={{ color: "red" }}>
                Firm Name is required
              </p>
            )}

            <FormControl sx={{ marginTop: "20px" }} fullWidth>
              <InputLabel id="demo-simple-select-autowidth-label">
                isActive
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={selectedFirm}
                label="Select isActive"
                onChange={handleFirmChange}
              >
                {["true", "false"].map((firm) => (
                  <MenuItem key={firm} value={firm}>
                    {firm}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions sx={{ marginRight: 2 }}>
            <Button
              type="submit"
              sx={{
                backgroundColor: COLORS.black,
                color: COLORS.white,
                "&:hover": {
                  backgroundColor: `${COLORS.black} !important`,
                  color: `${COLORS.white} !important`,
                },
                textTransform: "none",
              }}
            >
              {loading ? (
                <CircularProgress size={22} color="inherit" />
              ) : (
                "Save"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
export default FirmDialog;
